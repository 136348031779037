.footer .navbar {
  background-color: #f5a624;
  box-shadow: 8px 2px 10px rgba(77, 77, 77, 0.589);
}

.footer .navbar-light .navbar-nav .nav-link {
  color: #4a4a4a;
}

.footer .navbar-light .navbar-nav .nav-link:hover {
  color: white;
}

.footer .navbar-light .navbar-brand {
  color: #4a4a4a;
}

.footer .navbar-light .navbar-brand:hover {
  color: white;
}

/**
* Modal de suppression compte
*/
.modalButtonDelete {
  background-color: #f4a521 !important;
  color: white !important;
  border-radius: 58px !important;
  border-color: #f5a624 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.modalButtonDelete:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}
.modalBody {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  font-size: 18px;
}
