.backgroundTicket {
  background-color: #f5a624;
  width: 45mm;
  height: 70mm;
  margin-top: 2%;
  margin-left: 2%;
  padding-top: 1%;
}

.qr {
  margin-top: 11%;
}

#qrCodeDiv canvas {
  width: 100%;
}

#qrCodeDivResto canvas {
  width: 100%;
}

.backgroundTicketVisible {
  width: 100%;

  justify-content: center;
  margin-bottom: 3%;
}

.backgroundTicketVisibleWeb {
  justify-content: center;
  background-color: #f5a624;
  margin-bottom: 3%;
  padding-top: 8%;
}

.backgroundTicketVisibleWebCarte {
  justify-content: center;
  background-color: #f5a624;
  margin-bottom: 3%;
  padding-top: 8%;
  padding-bottom: 3%;
}

.QRPrint {
  display: none;
}

.rowCode {
  flex-direction: column;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  align-items: center;
  justify-content: center;
}

.rowCodeWeb {
  flex-direction: column;

  padding-bottom: 3%;
  padding-left: 5%;
  align-items: center;
  justify-content: center;
}

.paddingCode {
  padding-top: 10%;
  padding-bottom: 12%;
}

.backColLeft {
  background-color: rgb(34, 11, 11);
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
  padding-top: 6%;
  padding-left: 6%;
}

.backColRight {
  background-color: rgb(34, 11, 11);
  border-top-right-radius: 10%;
  border-bottom-right-radius: 10%;
  padding-right: 6%;
}
.rowCode2 {
  flex-direction: column;
  padding-top: 5%;
  padding-bottom: 3%;
  padding-left: 5%;
  align-items: center;
  justify-content: center;
  padding-right: 5%;
}

.rowCode2Web {
  flex-direction: column;
  padding-top: 25%;
  padding-bottom: 3%;
  padding-left: 5%;
  align-items: center;
  justify-content: center;
  padding-right: 5%;
}

.col2 {
  padding-top: 10%;
}

.tipPicture {
  width: 100px;
}

.tipPictureWeb {
  width: 100%;
}

.titleName {
  color: #555;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 21px;
  text-align: center;
}

.titleNamePrint {
  color: #555;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 20px;
  text-align: center;
}

.titleNamePrint {
  color: #555;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 13px;
  text-align: center;
}

.titleNameMenu {
  color: #555;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 10px;
  text-align: center;
}

.qrCodeTicket {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}

svg {
  height: 2em;
  width: 2em;
  color: white;
  width: 79%;
}

.buttonQrCode {
  border-radius: 28px !important;
  background-color: #f5a624 !important;

  border-color: #f5a624 !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  font-size: 18px !important;
  border: 0px solid #4a4a4a !important;
  color: white;
  border-width: 0px;
  margin-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
}
.buttonQrCode:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.line {
  border-bottom: 2px solid white;
  width: 58px;
  margin-bottom: 7%;
}

.colorRow {
  background-color: white;
  padding-top: 5%;
}

.colorRowPrint {
  background-color: white;
  margin-top: 15%;
}

.colorRow img {
  width: 100%;
}

.colorRowPrint img {
  width: 100%;
}

.colCenter {
  text-align: center;
}

.colMargin {
  margin-top: -8%;
}

.colMarginRap {
  margin-top: -12%;
}

.colMarginWord {
  margin-top: -3%;
}

.colMarginWord {
  margin-top: -3%;
}

.colMarginThanks {
  margin-top: 10%;
}
