.Telecharger{
    border-radius: 28px !important;
    background-color: #f5a624 !important;
    color:white;
    border-color: #f5a624 !important;
    border-width: 0px!important;
    font-size: 19px !important; 
    float:right;
    margin-bottom:2%;
    margin-right: 1%;
}


.bloc-data{
    margin-top: 3%;
}


.colorTitre{
    color:#f5a624
}