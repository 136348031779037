.homepage .qr {
  border: none;
}

.homepage .titleQR {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5%;
}

.homepage .rowGlobal {
  justify-content: center;
}
.inputAffi {
  border-radius: 28px;
  border-width: 1px;
  border-color: #9c9c9c14;
  margin-bottom: 6%;
  padding: 1%;

  box-shadow: 1px 1px 1px #999;
}

.affiPop {
  text-align: center;
}

.centerCol {
  text-align: center;
}

.colMenu p {
  font-size: 10px;
}
.marginToop {
  margin-top: 5%;
  margin-bottom: 10%;
}

.button {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  margin-left: 2%;
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 12px !important;
  border: 0px solid #4a4a4a !important;
  color: white;
  border-width: 0px;
}

.homepage .buttonPremium {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  margin-left: 2%;
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 15px !important;
  border: 0px solid #4a4a4a !important;
  color: white;
  border-width: 0px;
}
.homepage .buttonPremium:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: yellow !important;
}
.homepage .qrSub {
  margin-top: -15px;
  text-align: center;
  font-size: 14px;
}
/**
* Style modal tuto
*/

.buttonTuto {
  background-color: #7200ff !important;
  position: -webkit-sticky !important;
  font-size: 20px !important;
  z-index: 1000 !important;
  right: -6% !important;
  top: 45% !important;
  position: fixed !important;
  float: right !important;
  border-width: 0 !important;
  padding: 2% 2% 2% 2% !important;
  border-radius: 0px 0px 25px 25px !important;
  vertical-align: top !important;
  transform: rotate(90deg) !important;
  -ms-transform: rotate(90deg) !important; /* IE 9 */
  -moz-transform: rotate(90deg) !important; /* Firefox */
  -webkit-transform: rotate(90deg) !important; /* Safari and Chrome */
  -o-transform: rotate(90deg) !important; /* Opera */
}

.flecheTuto {
  margin-left: 0.4rem !important;
  background-color: #f5a624 !important;
  color: #7200ff !important;
  border-width: 0 !important;
  vertical-align: top !important;
  border-radius: 25px !important;
  transform: rotate(90deg) !important;
  -ms-transform: rotate(270deg) !important; /* IE 9 */
  -moz-transform: rotate(270deg) !important; /* Firefox */
  -webkit-transform: rotate(270deg) !important; /* Safari and Chrome */
  -o-transform: rotate(270deg) !important; /* Opera */
}
.flecheTuto:hover {
  border-color: #f5a624 !important;
  color: white !important;
}
.imgModalTuto {
  width: 30%;
  margin-bottom: 3%;
}
.modalButton {
  background-color: #f5a624 !important;
  border-color: #4a4a4a !important;
  color: #4a4a4a !important;
}
.modalButton:hover {
  border-color: #f5a624 !important;
  background-color: #4a4a4a !important;
  color: #f5a624 !important;
}
.liTitle {
  font-size: 16px;
  font-weight: 600;
}
.title1 {
  font-size: 20px;
  font-weight: 800 !important;
  text-align: center !important;
}
.para1 {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  font-size: 14px;
}

@media (max-width: 800px) {
  /**
*TUTO RESPONSIVE
*/
  .buttonTuto {
    background-color: #7200ff !important;
    position: -webkit-sticky !important;
    font-size: 20px !important;
    z-index: 1000 !important;
    right: -20% !important;
    position: fixed !important;
    float: right !important;
    border-width: 0 !important;
    padding: 3% 3% 3% 3% !important;
    border-radius: 0px 0px 25px 25px !important;
    vertical-align: top !important;
    transform: rotate(90deg) !important;
    -ms-transform: rotate(90deg) !important; /* IE 9 */
    -moz-transform: rotate(90deg) !important; /* Firefox */
    -webkit-transform: rotate(90deg) !important; /* Safari and Chrome */
    -o-transform: rotate(90deg) !important; /* Opera */
  }

  .flecheTuto {
    margin-left: 0.4rem !important;
    background-color: #f5a624 !important;
    color: #7200ff !important;
    border-width: 0 !important;
    border-radius: 25px !important;
    vertical-align: top !important;
    transform: rotate(90deg) !important;
    -ms-transform: rotate(270deg) !important; /* IE 9 */
    -moz-transform: rotate(270deg) !important; /* Firefox */
    -webkit-transform: rotate(270deg) !important; /* Safari and Chrome */
    -o-transform: rotate(270deg) !important; /* Opera */
  }
  .flecheTuto:hover {
    border-color: #f5a624 !important;
    color: white !important;
  }
  .imgModalTuto {
    width: 30%;
    margin-bottom: 3%;
  }
  .liTitle {
    font-size: 16px;
    font-weight: 600;
  }
}
