.menujour {
  font-family: "Montserrat", sans-serif;
  text-align: center;

  border: none;
  color: #555;
}
.menujour:hover {
  color: #f5a624;
}

.linkButton {
  text-decoration: none;
}
.bouton {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  width: 47%;
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 18px !important;
  margin-bottom: 20%;
  color: white;
  border-width: 0px;
}
.bouton:hover {
  background-color: #555 !important;
  color: white !important;
  border-color: yellow !important;
}
.legend {
  border-radius: 5px;
  font-size: 0.8rem;
  border: 2px solid #4a4a4a !important;
}

.cardsupp {
  text-align: center;
}

.boutonSupprimer {
  border-radius: 28px !important;
  background-color: #f5a624 !important;

  padding-left: 3%;
  padding-right: 3%;
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 18px !important;

  color: white;
  border-width: 0px;
}
.boutonSupprimer:hover {
  background-color: #555 !important;
  color: white !important;
}
.colMenu {
  text-align: center;
}

.centerRow {
  justify-content: center;
}

.formMenu {
  display: inline-block;
  text-align: center;
  align-items: center;
  margin-top: 10%;
}

.bouton:hover,
.boutonSupprimer:hover,
.boutonSupprimer:active,
.bouton:active {
  background-color: white;
  color: #f4a521;
}
