.personnel {
  background: #ffffff;
  font-family: "Montserrat", sans-serif;
  margin: auto;
  border-radius: 28px;
  border: none;
  color: #555;

  padding: 30px 70px;
}

.personnel .serverPicture {
  width: 100px;
  height: 100px;
}

.personnel .buttonRef {
  border-radius: 28px !important;
  background-color: #7200ff !important;
  margin-left: 2%;
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 12px !important;
  border: 0px solid #4a4a4a !important;
  color: #f5a624;
  border-width: 0px;
}

.button:hover {
  background-color: #555 !important;
  border-width: 0px !important;
}

.centerRef {
  justify-content: center;
  text-align: center;
}

.buttonNonRef {
  border-radius: 28px !important;
  background-color: #555 !important;
  margin-left: 2%;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  border: 0 solid #4a4a4a !important;
  color: #fff;
  border-width: 0;
  font-size: 12px;
}

.button:active {
  background-color: #555 !important;
}

.button:visited {
  background-color: #555 !important;
}

.personnel .button:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}
.personnel .titleWaiter {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 5%;
}
.personnel .linkButton {
  text-decoration: none !important;
}
.personnel .affiliation {
  text-align: center;
  margin-bottom: 10%;
}

.personnel .demandeAffiliation {
  margin-top: 5%;
  margin-bottom: 25px;
  color: #f4a521;
  font-size: 22px;
  font-weight: bold;
}
.personnel .infoAffi {
  font-weight: bold;
}
.personnel .colAffiliation {
  text-align: center;
}

.personnel .inputAffi {
  border-radius: 28px;
  border-width: 1px;
  border-color: #9c9c9c14;
  margin-bottom: 6%;
  padding: 1%;

  box-shadow: 1px 1px 1px #999;
}
.personnel .title-container {
  text-align: center;
  color: #555;
}
.personnel .title-container:hover {
  color: #f5a624;
}
.personnel .articles {
  text-align: center;
}
.personnel .crewStyle {
  margin-bottom: 5%;
}
.personnel .serveurDiv {
  text-align: center;
}
.personnel .serveurP {
  margin-left: 10%;
}
