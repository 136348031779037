.qrCode {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.buttonQrCode {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  margin-left: 2%;
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 18px !important;
  border: 0px solid #4a4a4a !important;
  color: white;
  border-width: 0px;
}
.buttonQrCode:hover {
  background-color: #555 !important;
  color: white !important;
  border-color: yellow !important;
}

.buttonQrCode:active {
  background-color: #555 !important;
  color: white !important;
  border-color: yellow !important;
}

.rowCenterWeb {
  justify-content: center;
  text-align: center;
}

.borderCol {
  background-color: white;
  padding: 6%;
}

.borderColPrint {
  justify-content: center;
  padding-top: 4%;
  margin-bottom: 4%;
  margin-left: 12%;
  margin-right: 12%;
  width: 15mm;
  background-color: white;
}

.rowMargin {
  margin-top: 5%;
}

.rowMarginCard {
  margin-bottom: 10%;
}

.text1 {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 21px;
}

.text1Print {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 15px;
  text-align: center;
}
.text1PrintMenu {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
  text-align: center;
}

.colMargin {
  margin-bottom: 5%;
}

.textDeux {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 21px;
}

.textDeuxPrint {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
}

.textDeuxMenu {
  font-family: "Barlow Condensed", sans-serif;
  color: #555;
  font-size: 21px;
}

.textDeuxPrintMenu {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
}

.text3 {
  font-family: "Barlow Condensed", sans-serif;
  color: #555;
  font-size: 21px;
}

.text3Menu {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 21px;
}

.text3Print {
  font-family: "Barlow Condensed", sans-serif;
  color: #555;
  font-size: 10px;
}

.text3PrintMenu {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
}
.text3PrintMenuGrey {
  font-family: "Barlow Condensed", sans-serif;
  color: #555;
  font-size: 10px;
}

.colThanks {
  margin-bottom: 5%;
}

.text4 {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 10px;
  padding-bottom: 3%;
}

.text4Ticket {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  font-size: 21px;
  padding-bottom: 3%;
}

.colMarginMenu {
  margin-top: -7%;
}

.marginrestau {
  margin-top: 6%;
}
