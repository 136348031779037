.menuContain .carousel {
  width: 100%;
}

.menuContain .menu {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  border-radius: 12px 9px 12px;
  text-align: center;
  border: none;
  color: #555;
  padding: 30px 30px 58px 30px;
}

.buttonMenu {
  font-size: 23px;
  margin: 2%;
  padding: 2%;
  background-color: #f4a521;
  color: white;
  font-size: 19px;
  border-radius: 28px;
  border: 0px solid #4a4a4a !important;
}
.buttonMenu:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}
.buttonMenuSup {
  font-size: 23px;
  margin: 2%;
  padding: 2%;
  background-color: #f4a521;
  color: white;
  font-size: 19px;
  border-radius: 28px;
  border: 0px solid #4a4a4a !important;
}
.buttonMenuSup:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}
.menuContain .menuOther {
  text-align: center;
}
.menuContain .maxFile {
  font-size: 12px;
}
