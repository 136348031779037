body {
  background-color: #ffffff;
}

.Bloc-principal {
  font-family: "Montserrat", sans-serif;
  padding-bottom: 5%;
  margin-top: 2%;
}

.Bloc-principal .form-group .form-control {
  font-size: 1.2rem;
  border-radius: 28px;
}
.Bloc-principal .form {
  display: inline-block;
}
.Bloc-principal .submitButton {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  width: 100%;
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 15px !important;
  text-align: center;
  margin-bottom: 20%;
}
.Bloc-principal .submitButton:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}
.Bloc-principal .annonceParrainage {
  color: #f5a624;
  font-size: 12px;
}
.Bloc-principal .checkboxCGUParrainage {
  color: #f5a624 !important;
  font-weight: bolder;
}
.Bloc-principal a {
  text-decoration: none !important;
}
.Bloc-principal .Titre {
  color: #4a4a4a;
}

.Bloc-principal .Titre h1 {
  font-size: 28px;
  color: #f5a624;
  margin-right: 10%;
}

.Bloc-principal .formBasicCheckbox {
  font-size: initial;
  color: #4a4a4a;
}

.Bloc-principal .cgvLinkParrainage {
  color: #f5a624 !important;
  padding-top: 3px;
  padding-left: 10px;
}
.Bloc-principal .detailPassword {
  font-size: 11px;
  margin-bottom: 3px;
}

.Bloc-principal .servicePropose {
  padding-left: 3%;
}

@media only screen and (min-device-width: 1024px) {
  .Bloc-principal .imageDeskRestau {
    display: block;
    background-color: #4a4a4a;
    background-image: url("/image/drink (7).jpg");
    background-repeat: no-repeat;
    width: 100%;
  }

  .Bloc-principal {
    margin-top: 0% !important;
    margin-right: 0% !important;
    padding-bottom: 0% !important;
  }

  .Bloc-principal .colInscr {
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
}
