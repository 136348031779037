.connexion-container a {
  color: #f5a624 !important;
}
.connexion-container {
  margin-top: -5%;
  background-color: white;
  border-radius: 28px;
  padding-top: 3%;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 30%;
}

.connexion-container h1 {
  font-size: 22px;
  margin-top: 5%;
  font-weight: bold;
}

.connexion-container .form-control {
  border-radius: 28px !important;
  margin-top: 5%;
}

.connexion-container .pwd-container {
  position: relative;
  width: 100%;
}
.connexion-container .pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 30px;
  right: 31px;
  top: 16px;
}

.forgetpwd {
  font-size: 16px;
  color: #e05858;
  display: inherit;
  margin-top: -20px;
  text-decoration: none !important;
  margin-top: 10px;
  text-align: right;
}

.blocCompte {
  text-align: right;
}
.connectButton {
  border-radius: 28px;
  border-color: #f5a624;
  background-color: #f5a624;
  color: white;
  padding-left: 28%;
  padding-right: 28%;
}

.connectButton:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
}

.text2 {
  font-size: 22px;
  font-weight: lighter;
  margin-top: 10px;
  margin-bottom: -10px;
  font-weight: bold;
}

.alignRight {
  text-align: right;
}

.form-control {
  padding: 1.375rem 0.75rem;
  margin-bottom: 3%;
}

@media only screen and (min-device-width: 1024px) {
  .connexion-container {
    margin-top: 0%;
  }
  .connexion-container h1 {
    font-size: 28px !important;
    margin-top: 0%;
  }
  .connexion-container .form-control {
    border-radius: 28px !important;
    margin-top: 3% !important;
  }
  .text2 {
    font-size: 22px;
    font-weight: lighter;
    margin-top: 10px;
    margin-bottom: -10px;
    font-weight: bold;
  }
  .forgetpwd {
    font-size: 14px;
    color: #e05858;
    display: inherit;
    margin-top: -20px;
    text-decoration: none !important;
    margin-top: 10px;
    text-align: right;
  }
  .form-control {
    padding: 1.9rem !important;
    margin-bottom: 2%;
    text-align: left !important;
  }
}
