.styleProfil {
  background: #ffffff;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 30px;
  border-radius: 12px 9px 12px;
  border: none;
  color: #4a4a4a;

  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  margin-bottom: 80px;
}
.styleProfil .qrSub {
  margin-top: -15px;
  text-align: center;
  font-size: 14px;
}

.styleProfil input {
  border-radius: 28px;
  border-width: 1px;
  padding: 5px;
}

.labelMargin {
  margin-right: 1%;
}

.styleProfil .rowCenter {
  justify-content: center;
}

.styleProfil .title {
  text-align: center;
  margin-bottom: 10%;
}
.styleProfil .formLogo {
  text-align: center;
  justify-content: center;
  align-items: center;
}
.styleProfil .formProfil {
  padding-top: 5px;
  margin-top: 0px;
}
.styleProfil .buttonVal {
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 18px !important;
  margin-bottom: 4%;
  border-width: 0px;
  margin-top: 10%;
  color: white;
  padding-left: 2%;
  padding-right: 2%;
}
.styleProfil .buttonVal:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}
.styleProfil .button {
  border-radius: 28px !important;
  background-color: #f5a624 !important;

  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 18px !important;
  margin-bottom: 10%;
  border-width: 0px;
  margin-top: 10%;
  padding-right: 2%;
  padding-left: 2%;

  color: white;
}

.button:hover {
  background-color: #555 !important;
}

.button:active {
  background-color: #555 !important;
}
.button:visited {
  background-color: #555 !important;
}

.styleProfil .button:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.styleProfil .profilTitle {
  text-align: center;
}

.modalStyle .buttonModal {
  background-color: #f4a521 !important;
  font-size: 0.8rem !important;
  border-radius: 5px !important;
  border: 2px solid #f4a521 !important;
  margin: 5px !important;
  font-size: 0.8rem !important;
  color: white !important;
}
.modalStyle .buttonModal:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}

.styleProfil .fas {
  font-size: 0.8rem;
  padding: 1%;
  margin-top: 2%;
  color: #f4a521;
}
.styleProfil .signOut1 {
  text-align: center;
  margin-top: 15px;
}

.styleProfil .formProfil {
  text-align: center;
}
.styleProfil .signOut {
  text-align: center;
  margin-top: 0px;
}
.styleProfil .nomProfil {
  font-size: 20px;
}
.styleProfil .center {
  text-align: center;
}
.styleProfil .addImage {
  margin-bottom: 30px;
}
.styleProfil .textProfil {
  color: #4a4a4a;
  text-align: center;
  margin-top: -3%;
}

.styleProfil .textProfilTitre {
  color: #f4a521;
}

.styleProfil .restaurantLogo {
  max-width: 200px;
}
/**
*Style parrainage 
*/
.parrainage .inputParrainage {
  padding: 0.5rem;
  border-radius: 28px;
  border-width: 1px;
  text-align: center !important;
  margin-bottom: 2%;
  margin-top: 3%;
}
.parrainage .demandeParrainage {
  color: #f4a521;
  font-size: 34px !important;
}
.parrainage .colParrainage {
  text-align: center !important;
  margin-top: 10%;
}

.parrainage .rowParrain {
  flex-direction: column;
}
.parrainage .buttonParrainage {
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 28px !important;
  background-color: #f5a624 !important;
  /* margin-left: 1%; */
  border-color: #f5a624 !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  font-size: 16px !important;
  border: 0px solid #4a4a4a !important;
  color: white;
  border-width: 0px;
}
.parrainage .buttonParrainage:hover {
  background-color: #555 !important;
  color: #f5a624 !important;
  border-color: #f5a624 !important ;
}
.parrainage .infoParrainage {
  font-weight: bold;
  margin-top: 2% !important;
  margin-bottom: 10% !important;
}
